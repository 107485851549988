<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-flex.md12.xs12
                  v-text-field(label="Nome", color="gray", required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", data-vv-as="Texto", type="text", v-model='categoria_feedback.texto')
              v-layout(row, wrap)
                v-flex.xs12
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    categoria_feedback: {},
  }),
  mounted () {
    const vm = this;
    if (vm.$route.params.id) {
      vm.getCategoriaFeedback(vm.$route.params);
    }
  },
  methods: {
    async save (categoria_feedback) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/categoria-feedback', categoria_feedback);
        categoria_feedback.id = response.data.id;
        vm.$router.push({ name: 'CategoriaFeedbackLista', params: { id: response.data.id } });
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Categoria de feedback salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a categoria de feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (categoria_feedback) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/categoria-feedback/${categoria_feedback.id}`, categoria_feedback);
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Categoria de feedback salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a categoria de feedback. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.categoria_feedback.id ? 'update' : 'save'](vm.categoria_feedback);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getCategoriaFeedback (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/categoria-feedback/${params.id}`);
        vm.categoria_feedback = response.data || {};
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>

